<h2>Billing</h2>

<div class="row" *ngIf="organization">
  <div class="col-md-6">
    <p *ngIf="organization">
      Cycle Start: <strong>{{ organization.billingCycleStart | date }}</strong>
    </p>
    <p>
      Current plan is
      <strong *ngIf="organization">{{ organization.planName }}</strong
      >.
    </p>

    <p>To view previous invoices, change credit card, or change billing address, please see billing portal:</p>

    <button class="btn btn-primary" (click)="goToBillingPortal()">Billing Portal</button>

    <h3>Switch Plan</h3>
    <select class="form-control" [(ngModel)]="newPlan">
      <option *ngFor="let plan of plans" [ngValue]="plan">
        {{ plan.tier }} -
        <ng-container *ngIf="plan.changePrice > 0"> Prorate: ${{ plan.changePrice / 100 }} this {{ plan.duration }} then</ng-container>
        ${{ plan.price }}/{{ plan.duration }} - {{ plan.reportCount }} Reports
      </option>
    </select>
    <!-- <pre>{{plans|json}}</pre> -->

    <p>For questions please reach out to billing&#64;csper.io</p>

    <p><a target="_blank" routerLink="/pricing">Plan Comparisons</a></p>
    <button class="btn btn-primary mr-2" (click)="changePlan()" [disabled]="!newPlan">Upgrade Plan</button>
    <button class="btn btn-danger" [disabled]="organization.planName == 'SANDBOX'" [routerLink]="['/org', organization.id, 'settings']">
      Cancel Plan
    </button>

    <p *ngIf="planChanged" class="text-success">Congrats! Plan successfully changed.</p>
  </div>

  <div class="col-md-6">
    <div class="card" *ngIf="organization && organization.planName == 'SANDBOX'">
      <h5 class="card-header">Upgrade To Business</h5>
      <div class="card-body">
        <h5 class="card-title pt-2">Take your CSP to the next level?</h5>
        <ul class="card-text">
          <li>Process 1,000,000 reports a month</li>
          <li>Custom report-uri domain</li>
          <li>Realtime XSS detection and alerting</li>
          <li>Report Spike monitoring</li>
          <li>User management with both project/organization access control</li>
          <li>Email Support</li>
        </ul>
        <button class="btn btn-primary" (click)="changePlan()" [disabled]="!newPlan">Upgrade Plan</button>
        <br />
        <small>Want a <a routerLink="/demo">demo</a>? Looking for <a href="/contact">something else</a>?</small>
      </div>
    </div>
  </div>
</div>
<!-- 
<div *ngIf=" organization && organization.planName !='SANDBOX'">
    <hr>


    <h3>Invoices</h3>

    <p><strong>Previous</strong></p>
    <table class=" table" *ngIf="invoices">
        <tr>
            <th>Invoice Date</th>
            <th>Invoice URL</th>
            <th>Amount</th>
            <th>Billing Email</th>
        </tr>

        <tr *ngFor="let invoice of invoices">
            <td>{{invoice.created*1000 | timeAgo }}</td>
            <td><a href="{{invoice.invoiceURL}}">{{invoice.id}}</a></td>
            <td>{{invoice.total/100 | currency}}</td>
            <td>{{invoice.customerEmail}}</td>
        </tr>
    </table>

    <p><strong>Future</strong></p>
    <table class="table" *ngIf="nextInvoice">
        <tr>
            <th>Invoice Date</th>
            <th>Amount</th>
            <th>Billing Email</th>
        </tr>

        <tr>
            <td>{{nextInvoice.nextPaymentAttempt*1000 | timeAgo }}</td>
            <td>
                {{nextInvoice.total/100 | currency}}
                <div *ngFor="let line of nextInvoice.lines; index as lineIndex">
                    <small>{{line.amount/100 | currency }} - {{line.description}}</small>
                </div>


            </td>
            <td>{{nextInvoice.customerEmail}}</td>
        </tr>


    </table>

</div> -->
