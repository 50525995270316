import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Organization } from "src/app/models/organization";
import { APIKey, ApikeyService } from "src/app/services/apikey.service";
import { OrganizationService } from "src/app/services/organization.service";
import { ProjectService } from "src/app/services/project.service";

@Component({
  selector: "app-organization-apikey",
  templateUrl: "./organization-apikey.component.html",
  styleUrls: ["./organization-apikey.component.css"],
})
export class OrganizationApikeyComponent {
  organizationID: string;
  organization: Organization;
  isCorrectPlan: boolean;
  apiKeys: APIKey[] = [];

  newAPIKey: APIKey;

  errorStr = "";
  newAPIKeyError = "";

  constructor(private route: ActivatedRoute, private apiKeyService: ApikeyService, private organizationService: OrganizationService) {}

  ngOnInit() {
    this.organizationID = this.route.parent.snapshot.paramMap.get("organizationID");
    this.organizationService.getOrganization(this.organizationID).subscribe((organization) => {
      this.organization = organization;
      this.isCorrectPlan = this.organization.planName.startsWith("BUSINESS") || this.organization.planName.startsWith("ENTERPRISE");
    });

    this.newAPIKey = {
      organizationID: this.organizationID,
    } as APIKey;

    this.apiKeyService.getAPIKeysForOrganization(this.organizationID).subscribe((apiKeys) => {
      this.apiKeys = apiKeys;
      if (!this.apiKeys) {
        this.apiKeys = [];
      }
    });
  }

  saveAPIKey() {
    this.newAPIKey.organizationID = this.organizationID;
    this.apiKeyService.newAPIKey(this.newAPIKey).subscribe(
      (key) => {
        this.apiKeys.push(key);
        this.newAPIKey = key;
      },
      (e) => {
        this.newAPIKeyError = e.error;
      }
    );
  }

  deleteAPIKey(key: APIKey) {
    this.apiKeyService.deleteAPIKey(key.id).subscribe(() => {
      this.apiKeys = this.apiKeys.filter((k) => k !== key);
    });
  }
}
