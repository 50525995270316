import { Component, OnInit } from "@angular/core";
import { Organization } from "src/app/models/organization";
import { Overview } from "src/app/models/overview";
import { User } from "src/app/models/user";
import { Invoice } from "src/app/models/invoice";
import { ActivatedRoute, Router } from "@angular/router";
import { OrganizationService } from "src/app/services/organization.service";
import { BillingService } from "src/app/services/billing.service";
import { Plan } from "src/app/models/plan";
import { environment } from "src/environments/environment";

declare var Stripe: any;

@Component({
  selector: "app-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.css"],
})
export class BillingComponent implements OnInit {
  organizationID: string;
  organization: Organization;

  me: User;

  invoices: Invoice[];
  nextInvoice: Invoice;

  error: string;

  plans: Plan[];
  newPlan: Plan;

  planChanged: boolean;

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private billingService: BillingService,
    private router: Router
  ) {
    this.planChanged = false;
  }

  ngOnInit() {
    this.plans = [];

    var tag = document.createElement("script");
    tag.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName("head")[0].appendChild(tag);

    this.organizationID = this.route.parent.snapshot.paramMap.get("organizationID");
    this.organizationService.getOrganization(this.organizationID).subscribe((p) => {
      this.organization = p;

      this.billingService.getPlanChangeCost(this.organizationID).subscribe((p) => {
        for (var i = 0; i < p.length; i++) {
          if (p[i].name != "SANDBOX" && p[i].name != this.organization.planName) {
            this.plans.push(p[i]);
            this.newPlan = p[i];
          }
        }
      });
    });

    this.billingService.getNextInvoice(this.organizationID).subscribe((next) => (this.nextInvoice = next));
    this.billingService.getInvoices(this.organizationID).subscribe((i) => (this.invoices = i));
  }

  changePlan() {
    if (this.organization.planName == "SANDBOX" || this.organization.planName == "TEMP") {
      this.billingService.changePlan(this.organizationID, this.newPlan).subscribe((p) => {
        const stripe = Stripe(environment.stripeKey);

        stripe
          .redirectToCheckout({
            sessionId: p.checkoutSessionID,
          })
          .then(function (result) {});
      });
    } else {
      this.billingService.changePlan(this.organizationID, this.newPlan).subscribe((p) => {
        this.planChanged = true;
        this.ngOnInit();
      });
    }
  }

  goToBillingPortal() {
    let billingUrl = this.billingService.getBillingPortalURL(this.organizationID);

    window.location.href = billingUrl;
  }
}
