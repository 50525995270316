<!-- <div *ngIf="organization.p -->

<div *ngIf="organization">
  <div *ngIf="errorStr" class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Error:</strong> {{ errorStr }}
    <button type="button" class="close" (click)="errorStr = ''" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div *ngIf="!isCorrectPlan">
    <p>This feature is only available to BUSINESS/ENTERPRISE customers.</p>
    <p>
      See <a [routerLink]="['/pricing']">pricing</a> for more information. To upgrade please visit
      <a [routerLink]="['/org', organizationID, 'billing']">Billing</a>
    </p>
  </div>

  <div *ngIf="isCorrectPlan">
    <div class="row">
      <div class="col-md-8">
        <button class="btn btn-success float-right" data-toggle="modal" data-target="#newAPIKeyModal">New API Key</button>
        <h3>API Keys</h3>

        <div class="card mb-2">
          <div class="card-body">
            <h5 class="card-title">API Keys</h5>
            <table class="table" *ngIf="organization">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Comment</th>
                  <th>Remove</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let apiKey of apiKeys">
                  <td>{{ apiKey.name }}</td>
                  <td>{{ apiKey.role }}</td>
                  <td>{{ apiKey.comment }}</td>
                  <td>
                    <button class="btn btn-danger" (click)="deleteAPIKey(apiKey)">Remove</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Docs</h5>
            <p>API Keys are used to interact with the Csper API. API keys can be created for either single 'projects' or entire 'organizations'.</p>
            <p>For information on how to use the API, see the <a [routerLink]="['/docs', 'api']">API documentation</a>.</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">API Key Role Information</h5>

            <p>Adding a user to a project instead of an organization only grants them access to the specific project.</p>

            <p><strong>ADMIN</strong>: Can perform any action on the project, including user management, and project deletion.</p>

            <p><strong>WRITE</strong> Grants WRITE access within the project. Can setup new alerts, change settings.</p>

            <p>Does not grant access to user management, nor deleting the project</p>

            <p><strong>READ</strong> Grants READ access to all data within the project. Can not make any changes to any project</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" id="newAPIKeyModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Create new API Key for <strong>{{ organization.name }}</strong>
            </h5>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>

          <div class="modal-body">
            <div class="form-group">
              <label>Name</label>
              <input placeholder="name" type="text" class="form-control" [(ngModel)]="newAPIKey.name" />
            </div>

            <div class="form-group">
              <label>Role</label>
              <select [(ngModel)]="newAPIKey.role" class="form-control" required>
                <option>READ</option>
                <option>WRITE</option>
                <option>ADMIN</option>
              </select>
            </div>

            <div class="form-group">
              <label>Comment</label>
              <input placeholder="comment" type="text" class="form-control" [(ngModel)]="newAPIKey.comment" />
            </div>

            <div *ngIf="newAPIKey.token" class="alert alert-success alert-dismissible fade show" role="alert">
              <strong>API Key (will not be shown again):</strong><br />
              {{ newAPIKey.id }}:{{ newAPIKey.token }}
              <button type="button" class="close" (click)="newAPIKey.token = ''" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div *ngIf="newAPIKeyError" class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>Error:</strong> {{ newAPIKeyError }}
              <button type="button" class="close" (click)="newAPIKeyError = ''" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn btn-primary" (click)="saveAPIKey()">Add Key</button>
            <button class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
